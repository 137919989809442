<template>
    <ValidationObserver ref="updateProfileForm">
        <div class="container scope">
            <section class="mt-3">
                <div class="mb-3">
                    <h2 class="header-label">
                        {{ t("accounts.myAccount") }}
                    </h2>
                </div>
                <b-row>
                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2800"
                    >
                        <EKInputText
                            class="d-block w-75 mx-auto sign-up"
                            v-model="customerOrders.fullName"
                            :rules="[
                                {
                                    type: 'required',
                                    message: t('accounts.validation.fullName')
                                }
                            ]"
                            :label="t('accounts.fullName')"
                            name="fullName"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                    >
                        <EKInputText
                            class="d-block w-75 mx-auto sign-up"
                            v-model="customerOrders.email"
                            :rules="[
                                { type: 'required', message: 'الإيميل مطلوب' },
                                {
                                    type: 'email',
                                    message: t('accounts.validation.email')
                                }
                            ]"
                            :label="t('accounts.email')"
                            name="email"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2800"
                    >
                        <EKInputText
                            class="d-block w-75 mx-auto sign-up"
                            v-model="customerOrders.password"
                            :rules="[
                                {
                                    type: 'required',
                                    message: t('accounts.validation.password')
                                }
                            ]"
                            :label="t('accounts.password')"
                            name="password"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                    >
                        <EKInputText
                            class="d-block w-75 mx-auto sign-up"
                            v-model="customerOrders.confirmPassword"
                            :rules="[
                                {
                                    type: 'required',
                                    message: t(
                                        'accounts.validation.confirmPassword'
                                    )
                                }
                            ]"
                            :label="t('accounts.confirmPassword')"
                            name="confirmPassword"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2800"
                    >
                        <EKInputText
                            class="d-block w-75 mx-auto sign-up"
                            v-model="customerOrders.mobileNumber"
                            :rules="[
                                {
                                    type: 'required',
                                    message: t(
                                        'accounts.validation.mobileNumber'
                                    )
                                },
                                {
                                    type: 'mobile',
                                    message: t(
                                        'accounts.validation.validMobile'
                                    )
                                }
                            ]"
                            :label="t('accounts.mobileNumber')"
                            name="mobileNumber"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                    >
                        <EKInputSelect
                            class="d-block w-75 mx-auto sign-up"
                            :label="t('accounts.country')"
                            :options="countries"
                            name="select"
                            :clearable="true"
                            v-model="customerOrders.countryId"
                        />
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        data-aos="fade-up"
                        data-aos-duration="2800"
                    >
                        <EKInputText
                            class="d-block w-75 mx-auto sign-up"
                            v-model="customerOrders.address"
                            :rules="[
                                {
                                    type: 'required',
                                    message: t('accounts.validation.address')
                                }
                            ]"
                            :label="t('accounts.address')"
                            name="address"
                        />
                    </b-col>
                </b-row>

                <b-button
                    data-aos="fade-up"
                    data-aos-duration="2400"
                    variant="flat-secondary"
                    class="text-nowrap d-flex align-items-center mt-2 d-block mx-auto"
                    style="justify-self: center; background: none; border: none; position: relative"
                    @click="updateAccount"
                >
                    <svg
                        width="159"
                        height="69"
                        viewBox="0 0 159 69"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d)">
                            <path
                                d="M17.1788 18.1497C17.6657 15.9859 19.5198 14.4014 21.7331 14.2578L143.676 6.34543C146.559 6.15839 149 8.44613 149 11.3349V49.5C149 52.2614 146.761 54.5 144 54.5H15.25C12.0458 54.5 9.6686 51.5285 10.372 48.4024L17.1788 18.1497Z"
                                fill="#196DA7"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_d"
                                x="0.246094"
                                y="0.334717"
                                width="158.752"
                                height="68.1653"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                    </svg>
                    <span
                        style="position: absolute; right: 5.6rem; color: #ffffff"
                    >
                        {{ t("accounts.update") }}
                    </span>
                </b-button>
            </section>

            <section data-aos="fade-up" data-aos-duration="2400">
                <div class="d-flex justify-content-center mt-3">
                    <h2 class="mt-4" style="color: white; font-weight: bold">
                        {{ t("accounts.purchases") }}
                    </h2>
                </div>

                <div
                    class="rounded mt-3"
                    style="
            padding: 2px;
            background: linear-gradient(
              45deg,
              #afadad,
              #ffffff,
              #bbbbbb,
              #ffffff,
              #bbbbbb,
              #ffffff
            );
            padding-bottom: 0.1px;
          "
                >
                    <EKTableCollapse
                        label="label"
                        :rows="activeCustomerList"
                        :columns="columns"
                        childrenLabel="orderDetails"
                        :colapseHeader="subColumns"
                        no_delete
                        no_select
                    >
                        <template slot="card-header">
                            {{ t("accounts").ordersTable }}
                        </template>
                        <template slot="header" slot-scope="{ headers }">
                            <tr class="vc__table__tr vc__thead__tr">
                                <th
                                    v-for="(header, i) in headers"
                                    :key="i"
                                    class="text-center"
                                >
                                    {{ t("accounts")[header.value] }}
                                </th>
                                <th class="text-center"></th>
                            </tr>
                        </template>
                        <template slot="item-td.name" slot-scope="{ tr }">
                            <td>
                                <div
                                    class="d-flex align-items-center justify-content-center"
                                >
                                    <img
                                        :src="
                                            tr.image
                                                ? $store.getters[
                                                      'app/domainHost'
                                                  ] +
                                                  '/' +
                                                  tr.image
                                                : ''
                                        "
                                        width="60"
                                        height="60"
                                        class="rounded-circle mr-1"
                                    />
                                    <div class="ml-1 text-left">
                                        <h6 class="text-white">
                                            {{ tr.name }}
                                        </h6>
                                        <small class="text-nowrap">
                                            <span
                                                v-for="(at,
                                                i) in tr.productAttribute"
                                                :key="i"
                                                >{{ at.Name
                                                }}{{
                                                    tr.length - 1 > i
                                                        ? " - "
                                                        : ""
                                                }}</span
                                            >
                                        </small>
                                    </div>
                                </div>
                            </td>
                        </template>

                        <template slot="item-totalPrice" slot-scope="{ tr }">
                            {{ tr.currency == 1 ? "$" : t("sp") }}
                            {{ tr.totalPrice | numFormat }}
                        </template>
                        <template slot="item-note" slot-scope="{ tr }">
                            {{ tr.note ? tr.note : "-" }}
                        </template>
                        <template slot="item-td.price" slot-scope="data">
                            <td>
                                {{
                                    data.tr.productAttribute.reduce(
                                        (pre, curr) => {
                                            return pre + curr.syrianValue;
                                        },
                                        data.tr.price
                                    ) | numFormat
                                }}
                                {{ data.parent.currency == 1 ? "$" : t("sp") }}
                            </td>
                        </template>
                        <template
                            slot="item-td.totalPrice"
                            slot-scope="{ tr, parent }"
                        >
                            <td>
                                {{
                                    tr.productAttribute.reduce((pre, curr) => {
                                        return (
                                            pre + curr.syrianValue * tr.quantity
                                        );
                                    }, tr.quantity * tr.price) | numFormat
                                }}
                                {{ parent.currency == 1 ? "$" : t("sp") }}
                            </td>
                        </template>
                        <template slot="item-dateFinished" slot-scope="{ tr }">
                            <span
                                v-if="tr.dateFinished"
                                style="color: #1ac9fb !important"
                            >
                                {{ t("accounts.finished") }}
                            </span>
                            <span v-else style="color: #fdb844 !important">
                                {{ t("accounts.pending") }}
                            </span>
                        </template>
                        <template slot="item-orderDate" slot-scope="{ tr }">
                            {{
                                new Date(tr.orderDate).toLocaleDateString(
                                    "en-GB"
                                )
                            }}
                        </template>
                    </EKTableCollapse>
                </div>
            </section>

            <b-col
                cols="12"
                class="d-flex py-3 justify-content-center"
                data-aos="fade-up"
                data-aos-duration="2400"
            >
                <EKPagination
                    class="pagination"
                    :items="customerOrders.orders"
                    v-model="activeCustomerList"
                    :pageLength="8"
                    no_select
                    no_delete
                />
            </b-col>
        </div>
    </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapActions, mapState, mapMutations } from "vuex";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import Swal from "sweetalert2";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import EKTableCollapse from "@Ekcore/components/EK-table-collapse";
import EKPagination from "@Ekcore/components/EK-pagination";

export default {
    components: {
        ValidationObserver,
        EKInputText,
        EKInputSelect,
        EKTableCollapse,
        EKPagination
    },
    data: () => ({
        columns: [
            {
                label: "رقم الطلب",
                value: "number"
            },
            {
                label: "إجمالي السعر",
                value: "totalPrice"
            },
            {
                label: "ملاحظة",
                value: "note"
            },
            {
                label: "تاريخ الطلب",
                value: "orderDate"
            },
            {
                label: "حالة الطلب",
                value: "dateFinished"
            }
        ],
        subColumns: [
            {
                label: "المنتج",
                value: "name"
            },
            {
                label: "سعر المنتج",
                value: "price"
            },
            {
                label: "الكمية",
                value: "quantity"
            },
            {
                label: "الكلفة الكلية",
                value: "totalPrice"
            }
        ],
        activeCustomerList: []
    }),
    created() {
        this.getWebsiteCustomerDetails(this.id);
        window.addEventListener("keypress", this.enterSubmit);
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    beforeDestroy() {
        window.removeEventListener("keypress", this.enterSubmit);
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState({
            countries: state => state.global.countries,
            customerOrders: state => state.websiteAccounts.customerOrders,
            selectedSubOrdersIds: state =>
                state.websiteAccounts.selectedSubOrdersIds
        })
    },
    methods: {
        enterSubmit({ key, code }) {
            if (key == "Enter" || code == "Enter") {
                this.updateAccount();
            }
        },
        ...mapActions(["updateCustomer", "getWebsiteCustomerDetails"]),
        openAccountsDetails(p) {
            console.log(p);
        },
        ...mapMutations(["Reverse_Rules_Agreement"]),
        changeRulesAgreement() {
            this.Reverse_Rules_Agreement();
        },
        changeSelectChildren(props) {
            this.selectedSubOrdersIds.value = [...props];
        },
        updateAccount() {
            this.$refs.updateProfileForm.validate().then(success => {
                if (
                    this.customerOrders.password !=
                    this.customerOrders.confirmPassword
                ) {
                    Swal.fire({
                        title: "كلمة السر غير متطابقة",
                        text: "كلمة السر غير متطابقة",
                        icon: "warning",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    return;
                }

                if (success) {
                    this.updateCustomer(this.customerOrders);
                }

                this.$refs.updateProfileForm.reset();
            });
        }
    }
};
</script>

<style scoped lang="scss">
.scope ::v-deep .header-label {
    color: white;
    font-weight: bold;
    text-align: center;
    margin: auto;
    position: relative;
}
.scope ::v-deep label {
    text-transform: capitalize;
}

.scope ::v-deep .table {
    border-collapse: collapse;
}

.scope ::v-deep .card {
    position: relatie;
    background: var(--custom-dark);
    background-color: #47494a;
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: 2px !important;
}

.scope ::v-deep .card + .d-flex {
    margin-bottom: 0px !important;
    padding-bottom: 1rem !important;
    background-color: #47494a;
}

.scope ::v-deep .vc__tbody__tr {
    border-bottom: 1px solid #ffffff1a;
    border-left: 0;
    border-right: 0;
}

.scope ::v-deep .card-header {
    display: flex;
    background-color: #47494a;
    padding: 20px !important;
    margin-top: 0px !important;
}

.scope ::v-deep .card-header {
    color: white !important;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 47px;
    letter-spacing: 0.2px;
    letter-spacing: 0.05rem;
    word-spacing: 0.2rem;
}

.scope ::v-deep .card-header::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 1px;
    background: linear-gradient(
        45deg,
        #afadad,
        #ffffff,
        #bbbbbb,
        #ffffff,
        #bbbbbb,
        #ffffff
    );
    margin: none !important;
}

.scope ::v-deep .vgt-table {
    background-color: inherit !important;
}

.scope ::v-deep .vgt-left-align {
    padding: 20px !important;
}

.scope ::v-deep .sign-up label {
    color: white;
}

.scope ::v-deep .sign-up .form-control {
    padding: 2.5rem;
    background-color: inherit;
    color: white;
    border: solid 3px white;
    border-radius: 0.357rem !important;
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
        inset 2px -2px 4px rgba(0, 0, 0, 0.1);
}

.scope ::v-deep th.vgt-left-align {
    color: white !important;
    line-height: 47px;
    font-weight: 350 !important;
    font-family: SST Arabic;
    font-size: 1.3rem !important;
    letter-spacing: 0.2px;
}

.scope ::v-deep td.vgt-left-align {
    color: white !important;
    font-size: 1.1rem;
    line-height: 47px;
    font-weight: 300 !important;
    font-family: SST Arabic;
}

.scope ::v-deep .vs__dropdown-toggle {
    padding: 1.2rem;
    border: solid 3px white !important;

    background-color: inherit !important;
}

.scope ::v-deep .vs__search::placeholder {
    color: #b9b9c3 !important;
    opacity: 1;
}

.scope ::v-deep .vs__search {
    border: none;
}

.scope ::v-deep .v-select {
    border: none;
}

.scope ::v-deep .vs__selected {
    color: white;
}

.scope ::v-deep .add-button {
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    border: 2px solid
        linear-gradient(
            269.77deg,
            #afadad 0.31%,
            #ffffff 20.95%,
            #bbbbbb 57.03%,
            #ffffff 79.18%,
            #bbbbbb 105.77%,
            #ffffff 121.85%
        );

    background-color: inherit !important;

    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
        inset 2px -2px 4px rgba(0, 0, 0, 0.1);
}

.scope ::v-deep .custom-control-label:after {
    border: white solid 2px;
    border-radius: 1.5px;
    background-color: var(--custom-dark);
}

.scope ::v-deep .custom-control-label {
    color: #ffffff;
}

.scope ::v-deep .card-header {
    color: #ffffff;
}

.scope ::v-deep .blue-ball {
    &:after,
    &:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        transition: 0.3s;
    }
}

.scope ::v-deep .vc__table__tr.vc__thead__tr {
    color: white !important;
    font-weight: 500 !important;
}

.scope ::v-deep .vc__table__tr.vc__thead__tr {
    padding: 20px !important;
    background-color: #47494a;
}

.scope ::v-deep .vc__table.borderd .vc__table__tr.vc__thead__tr {
    color: #1ac9fb !important;
    background: #4c5157;
    font-weight: 500 !important;
}

.scope ::v-deep .vc__table__tbody {
    background-color: #47494a;
}

.scope ::v-deep .vc__table__tr.vc__thead__tr {
    th {
        padding: 35px !important;
    }
}

.scope ::v-deep .vc__table__tr.vc__tbody__tr {
    td {
        color: white !important;
        padding: 40px !important;
    }
}

.scope .vc__table__tbody .vc__table__tbody .vc__tbody__tr td {
    padding: 20px !important;
}

.scope ::v-deep .vc__table__tr.vc__tbody__tr {
    td {
        font-size: 1.1rem;
    }
}

.scope ::v-deep .vc__table.borderd .vc__table__tr.vc__tbody__tr {
    background: #4c5157;

    td {
        font-size: 1rem;
        font-weight: 400;
    }
}

.scope ::v-deep .update-button {
    justify-self: center;
    background: none;
    border: none;
    position: relative;
}

.scope ::v-deep .vc__table.borderd .vc__table__tr.vc__thead__tr {
    th {
        font-size: 1.1rem;
        font-weight: 500;
    }
}

.scope ::v-deep .vc__table.borderd caption {
    background: #4c5157 !important;
}

.scope ::v-deep .card-footer {
    background-color: #47494a !important;
}

.scope ::v-deep .page-link {
    color: #29ccfb !important;
}

.scope ::v-deep .card-footer {
    display: none;
    border: none;
}

.scope ::v-deep .vc__table-container .vc__table .vc__table__tr .colapse-icon {
    border-left: none !important;
    svg {
        fill: #fff;
    }
}

.pagination::v-deep {
    .page-item {
        border-radius: 50% !important;
        margin: 0 5px;
        padding: 3px;
        height: 35px !important;
        width: 35px !important;
        border-radius: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(165, 145, 145);
        background: linear-gradient(
            -180deg,
            rgba(238, 238, 238, 1) 0%,
            rgba(71, 73, 74, 1) 100%
        );
        .page-link {
            background-color: #47494a !important;
            color: #fff;
            border-radius: 5rem;
            border: 0;
            max-height: 35px !important;
            max-width: 35px !important;
        }
    }
    .page-item.active {
        height: 42px !important;
        width: 42px !important;
        padding: 2px;
        background: rgb(26, 201, 251);
        background: linear-gradient(
            180deg,
            rgba(26, 201, 251, 1) 0%,
            rgb(106, 109, 110) 100%
        );
        position: relative;
        top: -5px;

        .page-link {
            top: 0 !important;
            color: #1ac9fb !important;
            max-height: 39px !important;
            max-width: 39px !important;
        }
    }

    .prev-item,
    .next-item {
        display: none !important;
    }
}
</style>
